import { childrenService } from "../../_services";
import { event } from "vue-analytics";

const getDefaultState = () => {
  return {
    //status: {},
    //is_saving: false,
    list: [],
    is_loaded: false,
  };
};

const state = getDefaultState();

const actions = {
  create({ commit, rootGetters, dispatch }, { child, avatar }) {
    return new Promise((resolve, reject) => {
      if (!child.description) child.description = "";

      childrenService
        .createChild(rootGetters["diary/getName"](), child, avatar)
        .then(function(response) {
          commit("addSuccess", response.data);
          dispatch("diary/timeline/reset", null, { root: true });
          dispatch("diary/albums/reset", null, { root: true });
          resolve(response);
          event("Child", "Created", response.data.id, 1);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  createInDiary(
    { commit, rootGetters, dispatch },
    { diary_name, child, avatar }
  ) {
    return new Promise((resolve, reject) => {
      if (!child.description) child.description = "";

      childrenService
        .createChild(diary_name, child, avatar)
        .then(function(response) {
          if (diary_name == rootGetters["diary/getName"]()) {
            commit("addSuccess", response.data);
            dispatch("diary/timeline/reset", null, { root: true });
            dispatch("diary/albums/reset", null, { root: true });
          }

          resolve(response);
          event("Child", "Created", response.data.id, 1);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  update({ commit, dispatch }, child) {
    return new Promise((resolve, reject) => {
      childrenService
        .updateChild(child.id, child)
        .then(function(response) {
          var data = response.data;
          data.profile_loaded = true;

          commit("updateSuccess", data);
          dispatch("diary/timeline/reset", null, { root: true });
          dispatch("diary/albums/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  delete({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      childrenService
        .deleteChild(id)
        .then(function(response) {
          commit("deleteSuccess", id);
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(response);
          event("Child", "Deleted", response.data.id, 1);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  getProfileByName({ rootGetters, getters, commit }, name) {
    return new Promise((resolve, reject) => {
      var item = getters.getChildByUrlName(name);

      if (item && item.profile_loaded) {
        resolve({ data: item });
        return;
      }

      childrenService
        .getChildByUrlName(rootGetters["diary/getName"](), name)
        .then(function(response) {
          var data = response.data;
          data.profile_loaded = true;

          if (!data.description) data.description = "";

          commit("updateSuccess", data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  uploadAvatar({ commit, dispatch }, { id, file }) {
    return new Promise((resolve, reject) => {
      childrenService
        .uploadAvatar(id, file)
        .then(function(response) {
          commit("updateAvatarSuccess", { id, avatar: response.data });
          dispatch("diary/timeline/reset", null, { root: true });
          dispatch("diary/albums/reset", null, { root: true });
          resolve(response);
          event("Child", "Avatar-Uploaded", id, 1);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  deleteAvatar({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      childrenService
        .deleteAvatar(id)
        .then(function(response) {
          commit("updateAvatarSuccess", { id, avatar: null });
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(response);
          event("Child", "Avatar-Deleted", id, 1);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  // SKAPA
  updateChildrenOrder({ commit, rootGetters }, childrenId) {
    return new Promise((resolve, reject) => {
      childrenService
        .updateChildrenOrder(rootGetters["diary/getName"](), childrenId)
        .then(function(response) {
          commit("updateOrderSuccess", childrenId);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  mediaDeleted({ commit }, fileName) {
    commit("mediaDeleted", fileName);
  },

  reset({ commit }) {
    commit("reset");
  },
};

function sortChildren(temp) {
  temp.sort((a, b) => (a.sort > b.sort ? 1 : b.sort > a.sort ? -1 : 0));
}

const mutations = {
  loadSuccess(state, list) {
    state.is_loaded = true;
    state.list = list;
    sortChildren(state.list);
  },
  addSuccess(state, child) {
    if (!child.description) child.description = "";

    state.list.push(child);
    sortChildren(state.list);
  },
  updateSuccess(state, updatedChild) {
    state.list = [
      ...state.list.filter((element) => element.id !== updatedChild.id),
      updatedChild,
    ];
    sortChildren(state.list);
  },
  updateDescriptionSuccess(state, { id, description }) {
    let obj = state.list.find((element) => element.id === id);

    if (obj) {
      obj.description = description;
    }
  },
  updateAvatarSuccess(state, { id, avatar }) {
    let obj = state.list.find((element) => element.id === id);

    if (obj) {
      obj.avatar = avatar;
    }
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  deleteSuccess(state, id) {
    state.list = state.list.filter((child) => child.id !== id);
    sortChildren(state.list);
  },
  mediaDeleted(state, fileName) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.avatar && obj.avatar.file_name == fileName) {
          obj.avatar = null;
        }
      }
    }
  },
  updateOrderSuccess(state, orders) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < orders.length; i++) {
        let obj = state.list.find((element) => element.id === orders[i]);

        if (obj) {
          obj.sort = i + 1;
        }
      }
      sortChildren(state.list);
    }
  },
};

const getters = {
  getChildById: (state) => (id) => {
    return state.list.find((child) => child.id === id);
  },
  getChildByUrlName: (state) => (urlName) => {
    return state.list.find((child) => child.url_name === urlName);
  },
};

export const children = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
