const UserHome = () =>
  import(/* webpackChunkName: "account" */ "../views/account/profile/Home.vue");
const Password = () =>
  import(
    /* webpackChunkName: "account" */ "../views/account/profile/Password.vue"
  );
const Connections = () =>
  import(
    /* webpackChunkName: "account" */ "../views/account/profile/Connections.vue"
  );
const Avatar = () =>
  import(
    /* webpackChunkName: "account" */ "../views/account/profile/Avatar.vue"
  );
const Email = () =>
  import(
    /* webpackChunkName: "account" */ "../views/account/profile/Email.vue"
  );
const Logout = () =>
  import(
    /* webpackChunkName: "account" */ "../views/account/Logout.vue"
  );
const MessagesHome = () =>
  import(
    /* webpackChunkName: "account" */ "../views/account/message/Messages.vue"
  );
const ReadMessages = () =>
  import(
    /* webpackChunkName: "account" */ "../views/account/message/ReadMessage.vue"
  );
const MessageForm = () =>
  import(
    /* webpackChunkName: "account" */ "../views/account/message/MessageForm.vue"
  );
const NotificationsHome = () =>
  import(
    /* webpackChunkName: "account" */ "../views/account/Notifications.vue"
  );
const Following = () =>
  import(/* webpackChunkName: "account" */ "../views/account/Following.vue");
const SettingsMaster = () =>
  import(
    /* webpackChunkName: "account" */ "../views/account/profile/Master.vue"
  );

const accountroutes = [
  {
    path: "settings",
    //name: 'diary_child_profile',
    component: SettingsMaster,
    children: [
      {
        path: "email",
        name: "account_email",
        component: Email,
      },
      {
        path: "avatar",
        name: "account_avatar",
        component: Avatar,
      },
      {
        path: "password",
        name: "account_password",
        component: Password,
      },
      {
        path: "connections",
        name: "account_connections",
        component: Connections,
      },
      {
        path: "",
        name: "account_home",
        component: UserHome,
      },
      {
        path: "*",
        redirect: () => {
          return "/account/settings";
        },
      },
    ],
  },

  {
    path: "logout",
    name: "account_logout",
    component: Logout,
  },

  {
    path: "messages",
    name: "account_messages",
    component: MessagesHome,
  },
  {
    path: "messages/new",
    name: "account_messages_new",
    component: MessageForm,
  },
  {
    path: "messages/:id/answer",
    name: "account_messages_answer",
    component: MessageForm,
  },
  {
    path: "messages/:id",
    name: "account_messages_read",
    component: ReadMessages,
  },
  {
    path: "following",
    name: "account_following",
    component: Following,
  },
  {
    path: "notifications",
    name: "account_notifications",
    component: NotificationsHome,
  },

  {
    path: "",
    redirect: () => {
      return "/account/settings";
    },
  },
  {
    path: "*",
    redirect: () => {
      return "/account/settings";
    },
  },
];

export default accountroutes;
