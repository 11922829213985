import { diaryService } from '../../_services';

const getDefaultState = () => {
    return {
        is_loading: false,
        result: {},
        error: null
    }
}

const state = getDefaultState();

const actions = {
    searchInDiary({ commit, rootGetters }, search) {
        return new Promise((resolve, reject) => {
            commit('loadRequest');

            diaryService.searchInDiary(rootGetters['diary/getName'](), search)
                .then(function (response) {
                    commit('loadSuccess', response.data);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit('loadFailure', error);
                    reject(error);
                });
        });
    },
    reset({ commit }) {
        commit('reset');
    }
};

const mutations = {
    loadRequest(state) {
        state.is_loading = true;
        state.error = null;
    },
    loadSuccess(state, data) {
        state.is_loading = false;
        state.result = data;
    },
    loadFailure(state, error) {
        state.is_loading = false;
        state.error = error;
    },
    reset(state) {
        Object.assign(state, getDefaultState());
    }
};

export const search = {
    namespaced: true,
    state,
    actions,
    mutations
};