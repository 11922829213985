import { accountService, diaryService } from "../../_services";
import { imageHelpers, responseHelpers } from "../../_helpers";
import { accountStore } from "../browser";
import { event } from "vue-analytics";
import { messages } from "./messages.module";
import { notifications } from "./notifications.module";

const state = getDefaultState();

function getDefaultState() {
  const user = accountStore.currentUser();

  return getStateFromUser(user);
}

function getStateFromUser(user) {
  const obj = {
    status: {
      is_authorizing: false,
      is_logedin: false,
      is_expired: false,
      fb_authorizing: false,
    },
    user: null,

    session: null,
  };

  if (user && user.user && user.token && user.expire) {
    obj.user = user.user;
    obj.session = { access_token: user.token, expire: user.expire };
    obj.status.is_logedin = true;
  }
  return obj;
}

const actions = {
  init({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      var user = accountStore.currentUser();

      if (!user) {
        resolve();
      } else {
        accountService
          .currentUser()
          .then(function(response) {
            commit("setCurrentUser", response.data);
            resolve(response);
          })
          .catch(function(error) {
            dispatch("logout");
            reject(error);
          });
      }
    });
  },
  register({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("authorizeRequest", user);
      event("User", "Create-Account-Request", "", 1);

      accountService
        .register(user)
        .then(function(response) {
          event("User", "Create-Account-Success", "", 1);

          commit("authorized", response.data);
          resolve(response);
        })
        .catch(function(error) {
          event("User", "Create-Account-Failed", "", 1);

          commit("authorizeFailure");
          reject(error);
        });
    });
  },
  createSimple({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("authorizeRequest", user);
      event("User", "Create-Account-Simple-Request", "", 1);

      accountService
        .registerSimple(user)
        .then(function(response) {
          event("User", "Create-Account-Simple-Success", "", 1);

          commit("authorized", response.data);
          resolve(response);
        })
        .catch(function(error) {
          event("User", "Create-Account-Simple-Failed", "", 1);

          commit("authorizeFailure");
          reject(error);
        });
    });
  },
  oauthConnect({ commit }, { type, token }) {
    return new Promise((resolve, reject) => {
      commit("fbAuthorizeRequest");
      event("User", "Social-Connect-Request", type, 1);

      accountService
        .oauthConnect(type, token)
        .then(function(response) {
          event("User", "Social-Connect-Success", type, 1);

          commit("authorized", response.data);
          resolve(response);
        })
        .catch(function(error) {
          event("User", "Social-Connect-Failed", type, 1);

          commit("authorizeFailure");
          reject(error);
        });
    });
  },
  login({ commit }, { username, password }) {
    return new Promise((resolve, reject) => {
      commit("authorizeRequest");
      event("User", "Login-Request", "", 1);

      accountService
        .login(username, password)
        .then(function(response) {
          event("User", "Login-Success", "", 1);

          commit("authorized", response.data);
          resolve(response);
        })
        .catch(function(error) {
          event("User", "Login-Failure", "", 1);

          commit("authorizeFailure");
          reject(error);
        });
    });
  },
  uploadAvatar({ commit, state }, file) {
    return new Promise((resolve, reject) => {
      commit("saveAvatarRequest");

      accountService
        .uploadAvatar(file)
        .then(function(response) {
          event("User", "Avatar-Uploaded", state.user.id, 1);
          commit("updateAvatarSuccess", response.data);
          resolve(response);
        })
        .catch(function(error) {
          commit("updateAvatarFailure");
          reject(error);
        });
    });
  },
  deleteAvatar({ commit }) {
    return new Promise((resolve, reject) => {
      commit("saveAvatarRequest");

      accountService
        .deleteAvatar()
        .then(function() {
          event("User", "Avatar-Deleted", state.user.id, 1);
          commit("updateAvatarSuccess", "");
          resolve();
        })
        .catch(function(error) {
          commit("updateAvatarFailure");
          reject(error);
        });
    });
  },
  updateProfile({ commit }, user) {
    return new Promise((resolve, reject) => {
      //commit('saveRequest');

      accountService
        .updateProfile(user)
        .then(function() {
          commit("updateSuccess", user);
          resolve();
        })
        .catch(function(error) {
          commit("updateFailure");
          reject(error);
        });
    });
  },

  saveWelcome({ commit }, user) {
    return new Promise((resolve, reject) => {
      accountService
        .saveWelcomeInfo(user)
        .then(function() {
          commit("updateWelcomeSuccess", user);
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  closeWelcome({ commit }) {
    return new Promise((resolve, reject) => {
      accountService
        .closeWelcomeInfo()
        .then(function() {
          commit("updateWelcomeSuccess", null);
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  reset({ commit }) {
    commit("reset");
  },
  logout({ dispatch, commit }) {
    accountService.logout();
    commit("logout");
    event("User", "Logout", "", 1);

    commit("diary/userLogout", null, { root: true });

    dispatch("messages/reset");
  },

  createDiary({ commit }, diary) {
    return new Promise((resolve, reject) => {
      diaryService
        .createDiary(diary)
        .then(function() {
          accountService
            .currentUser()
            .then(function(response) {
              commit("onlineReloadSuccess", response.data);
              resolve();
            })
            .catch(function() {
              window.location = "/";
            });
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  reloadUser({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      accountService
        .currentUser()
        .then(function(response) {
          commit("onlineReloadSuccess", response.data);
          resolve();
        })
        .catch(function(error) {
          if (responseHelpers.getErrorResponseCode(error) === 401) {
            dispatch("sessionTimeout");
          }

          reject(error);
        });
    });
  },
  userStatusReloader({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      accountService
        .currentUserStatus()
        .then(function(response) {
          commit("userStatusReloaderSuccess", response.data);
          resolve();
        })
        .catch(function(error) {
          if (responseHelpers.getErrorResponseCode(error) === 401) {
            dispatch("sessionTimeout");
          }

          reject(error);
        });
    });
  },

  sessionTimeout({ commit }) {
    event("User", "Session-Timeout", "", 1);
    commit("sessionTimeout");
  },
};

const mutations = {
  authorizeRequest(state) {
    state.status.is_authorizing = true;
  },
  fbAuthorizeRequest(state) {
    state.status.is_authorizing = true;
    state.status.fb_authorizing = true;
  },
  authorized(state, data) {
    accountStore.setUser(data);

    state.status.is_logedin = true;
    state.user = data.user;
    state.session = { access_token: data.token, expire: data.expire };

    state.status.is_authorizing = false;
    state.status.fb_authorizing = false;
  },
  authorizeFailure(state) {
    state.status.is_authorizing = false;
    state.status.fb_authorizing = false;
  },

  setCurrentUser(state, data) {
    accountStore.updateUserInfo(data);

    state.user = data;
  },

  saveAvatarRequest() {
    //state.avatar_saving = true;
  },
  updateAvatarSuccess(state, avatarUrl) {
    var user = state.user;
    //state.avatar_saving = false;
    user.avatar = avatarUrl;
    accountStore.updateUserInfo(user);
    state.user = user;
  },
  updateAvatarFailure() {
    //state.avatar_saving = false;
  },

  updateSuccess(state, user) {
    state.user = Object.assign({}, state.user, user);
    //state.profile_saving = false;

    if (state.user.data && state.user.data.WelcomeDiaryModal) {
      delete state.user.data.WelcomeDiaryModal;
    }

    accountStore.updateUserInfo(state.user);
  },
  updateFailure() {
    //state.profile_saving = false;
  },

  updateWelcomeSuccess(state, data) {
    if (data != null) {
      state.user.username = data.username;

      if (state.user.current_diary) {
        state.user.current_diary.name = data.diary_name;
        state.user.current_diary.title = data.diary_title;
      }
    }

    if (state.user.data && state.user.data.WelcomeDiaryModal)
      delete state.user.data.WelcomeDiaryModal;

    accountStore.updateUserInfo(state.user);
  },
  resetNotifications(state) {
    state.user.unit_unread_notifications = 0;
    accountStore.updateUserInfo(state.user);
  },
  resetNewMessages(state) {
    state.user.unit_unread_pm = 0;
    accountStore.updateUserInfo(state.user);
  },

  reset(state) {
    Object.assign(state, getDefaultState());
  },
  logout(state) {
    Object.assign(state, getStateFromUser(null));
    accountStore.remove();
    localStorage.removeItem("hello");
  },

  onlineReloadSuccess(state, user1) {
    state.user = user1;
    accountStore.updateUserInfo(user1);
  },
  
  userStatusReloaderSuccess(state, data) {
    if(data.unit_unread_notifications)
      state.user.unit_unread_notifications = data.unit_unread_notifications;

    if(data.unit_unread_pm)
      state.user.unit_unread_pm = data.unit_unread_pm;

    accountStore.updateUserInfo(state.user);
  },

  sessionTimeout(state) {
    state.session = null;
    state.status.is_expired = true;
  },
};

const getters = {
  getAvatar: (state) => () => {
    return imageHelpers.accountAvatar(state.profile.avatar, 150);
  },
  isInRole: (state) => (roleName) => {
    if (state.user && state.user.roles) {
      var roles = state.user.roles.map(function(value) {
        return value.toUpperCase();
      });
      return roles.indexOf(roleName.toUpperCase()) > -1;
    }
    return false;
  },
};

const modules = {
  messages,
  notifications,
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules,
};
