import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import VuetifyToast from "vuetify-toast-snackbar";
import sv from "vuetify/es5/locale/sv";
import en from "vuetify/es5/locale/en";

import {
  mdiClose,
  mdiAccountGroup,
  mdiAccountGroupOutline,
  mdiLock,
  mdiEarth,
  mdiDotsVertical,
  mdiPlus,
  mdiPencil,
  mdiBaby,
  mdiMenuDown,
  mdiEye,
  mdiEyeOff,
  mdiArrowLeft,
  mdiFolderMultipleImage,
  mdiImageSearchOutline,
  mdiAlert,
  mdiAlertCircleOutline,
  mdiUpload,
  mdiMessageOutline,
  mdiArrowRight,
  mdiCheck,
  mdiShareVariant,
  mdiTrashCanOutline,
  mdiStar,
  mdiStarOutline,
  mdiCog,
  mdiCogOutline,
  mdiFacebook,
  mdiFacebookMessenger,
  mdiWhatsapp,
  mdiMessageAlert,
  mdiMessageAlertOutline,
  mdiImage,
  mdiImageAlbum,
  mdiImageOutline,
  mdiImageMultipleOutline,
  mdiImageEditOutline,
  mdiCommentPlusOutline,
  mdiAccountCancelOutline,
  mdiAccountCheckOutline,
  mdiFileDocumentEditOutline,
  mdiCalendarRangeOutline,
  mdiAccountBoxMultipleOutline,
  mdiAccountCircleOutline,
  mdiAlarm,
  mdiTagMultipleOutline,
  mdiCommentOutline,
  mdiHeart,
  mdiHeartOutline,
  mdiCloudAlert,
  mdiCommentSearchOutline,
  mdiAccountPlusOutline,
  mdiEmoticonSadOutline,
  mdiLogout,
  mdiShieldAccount,
  mdiAccountCancel,
  mdiAccountMultiple,
  mdiAccountEditOutline,
  mdiAccount,
  mdiBookOpenPageVariantOutline,
  mdiCloudUploadOutline,
  mdiDotsHorizontal,
  mdiSort,
  mdiNewspaperVariantOutline,
  mdiEmailOutline,
  mdiHumanMaleChild,
  mdiChartBellCurveCumulative,
  mdiRotate3dVariant,
  mdiHumanMaleFemale,
} from "@mdi/js";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faBaby,
  faChild,
  faStar,
  faNewspaper,
  faImages,
  faBirthdayCake,
  faCog,
  faComments,
  faBell,
  faGlobe,
  faSignOutAlt,
  faEnvelope,
  faEnvelopeOpen,
  faUserCircle,
  faKey,
  faHome,
  faUserPlus,
  faSignInAlt,
  faShoppingCart,
  faAsterisk,
  faUserSecret,
  faQuestionCircle,
  faUserLock,
  faCalendarAlt,
  faComment,
  faCommentMedical,
  faUsers,
  faGlobeEurope,
  faLock,
  faPen,
  faUser,
  faExclamationTriangle,
  faArrowLeft,
  faImage,
  faShareAlt,
  faBars,
  faFilter,
  faPlus,
  faAngleDown,
  faTrashAlt,
  faPaintBrush,
  faSearch,
  faTags,
  faChartArea,
  faHeart,
  faTools, faArrowsAltV
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faFacebookF,
  faGoogle,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faComments as farComments,
  faComment as farComment,
  faCommentDots,
  faUser as farUser,
  faCheckCircle as farCheckCircle,
  faEdit as farEdit,
  faFrown as farFrown,
} from "@fortawesome/free-regular-svg-icons";

Vue.component("font-awesome-icon", FontAwesomeIcon); // Register component globally
library.add(
  faBaby,
  faChild,
  faStar,
  faNewspaper,
  faImages,
  faBirthdayCake,
  faCog,
  faComments,
  faBell,
  faGlobe,
  faSignOutAlt,
  faEnvelope,
  faEnvelopeOpen,
  faUserCircle,
  faKey,
  faHome,
  faUserPlus,
  faSignInAlt,
  faShoppingCart,
  faAsterisk,
  faUserSecret,
  faQuestionCircle,
  faUserLock,
  faCalendarAlt,
  faComment,
  faCommentMedical,
  faUsers,
  faUser,
  faExclamationTriangle,
  faArrowLeft,
  faImage,
  faShareAlt,
  faBars,
  faFilter,
  faPlus,
  faAngleDown,
  faTrashAlt,
  faPaintBrush,
  faSearch,
  faTags,
  faChartArea,
  faHeart,
  faTools,
  faArrowsAltV
);
library.add(faGlobeEurope, faLock, faPen);

library.add(faFacebookF, faInstagram, faFacebook, faGoogle, faTwitter);

library.add(
  farComments,
  farComment,
  faCommentDots,
  farUser,
  farCheckCircle,
  farEdit,
  farFrown
);

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

const opts = {
  lang: {
    locales: { en, sv },
    current: process.env.VUE_APP_LANG,
  },
  icons: {
    iconfont: "mdiSvg",
    values: {
      fasChartArea: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "chart-area"],
        },
      },
      fasHeart: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "heart"],
        },
      },
      fasTags: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "tags"],
        },
      },
      fasSearch: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "search"],
        },
      },
      fasPaintBrush: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "paint-brush"],
        },
      },
      fasAngleDown: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "angle-down"],
        },
      },
      fasTrashAlt: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "trash-alt"],
        },
      },
      fasPlus: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "plus"],
        },
      },
      faFilter: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "filter"],
        },
      },
      fasBars: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "bars"],
        },
      },
      fasShareAlt: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "share-alt"],
        },
      },
      fasImage: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "image"],
        },
      },
      fasArrowLeft: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "arrow-left"],
        },
      },
      fasExclamationTriangle: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "exclamation-triangle"],
        },
      },
      fasBaby: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "baby"],
        },
      },
      fasChild: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "child"],
        },
      },
      fasStar: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "star"],
        },
      },
      fasArrowsAltV: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "arrows-alt-v"],
        },
      },
      fasNewspaper: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "newspaper"],
        },
      },
      fasImages: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "images"],
        },
      },
      fasBirthdayCake: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "birthday-cake"],
        },
      },
      fasCog: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "cog"],
        },
      },
      fasComments: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "comments"],
        },
      },
      fasBell: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "bell"],
        },
      },
      fasTools: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "tools"],
        },
      },
      fasGlobe: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "globe"],
        },
      },
      fasSignOutAlt: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "sign-out-alt"],
        },
      },
      fasEnvelope: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "envelope"],
        },
      },
      fasEnvelopeOpen: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "envelope-open"],
        },
      },
      fasUser: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "user"],
        },
      },
      fasUserCircle: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "user-circle"],
        },
      },
      fasKey: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "key"],
        },
      },
      fasHome: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "home"],
        },
      },
      fasUserPlus: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "user-plus"],
        },
      },
      fasSignInAlt: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "sign-in-alt"],
        },
      },
      fasShoppingCart: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "shopping-cart"],
        },
      },
      fasAsterisk: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "asterisk"],
        },
      },
      fasUserSecret: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "user-secret"],
        },
      },
      fasQuestionCircle: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "question-circle"],
        },
      },
      fasUserLock: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "user-lock"],
        },
      },
      fasCalendarAlt: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "calendar-alt"],
        },
      },
      fasComment: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "comment"],
        },
      },
      fasCommentMedical: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "comment-medical"],
        },
      },
      fasUsers: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "users"],
        },
      },
      fasGlobeEurope: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "globe-europe"],
        },
      },
      fasLock: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "lock"],
        },
      },
      fasPen: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fas", "pen"],
        },
      },

      farComments: {
        component: FontAwesomeIcon,
        props: {
          icon: ["far", "comments"],
        },
      },
      farComment: {
        component: FontAwesomeIcon,
        props: {
          icon: ["far", "comment"],
        },
      },
      farCommentDots: {
        component: FontAwesomeIcon,
        props: {
          icon: ["far", "comment-dots"],
        },
      },
      farUser: {
        component: FontAwesomeIcon,
        props: {
          icon: ["far", "user"],
        },
      },
      farCheckCircle: {
        component: FontAwesomeIcon,
        props: {
          icon: ["far", "check-circle"],
        },
      },
      farEdit: {
        component: FontAwesomeIcon,
        props: {
          icon: ["far", "edit"],
        },
      },
      farFrown: {
        component: FontAwesomeIcon,
        props: {
          icon: ["far", "frown"],
        },
      },
      fabFacebook: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fab", "facebook"],
        },
      },
      fabFacebookF: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fab", "facebook-f"],
        },
      },
      fabInstagram: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fab", "instagram"],
        },
      },
      fabGoogle: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fab", "google"],
        },
      },
      fabTwitter: {
        component: FontAwesomeIcon,
        props: {
          icon: ["fab", "twitter"],
        },
      },

      close: mdiClose,
      accountGroup: mdiAccountGroup,
      accountGroupOutline: mdiAccountGroupOutline,
      lock: mdiLock,
      earth: mdiEarth,
      dotsVertical: mdiDotsVertical,
      plus: mdiPlus,
      pencil: mdiPencil,
      baby: mdiBaby,
      menuDown: mdiMenuDown,
      eye: mdiEye,
      eyeOff: mdiEyeOff,
      arrowLeft: mdiArrowLeft,
      arrowRight: mdiArrowRight,
      folderMultipleImage: mdiFolderMultipleImage,
      imageSearchOutline: mdiImageSearchOutline,
      alert: mdiAlert,
      alertCircleOutline: mdiAlertCircleOutline,
      upload: mdiUpload,
      messageOutline: mdiMessageOutline,
      check: mdiCheck,
      shareVariant: mdiShareVariant,
      trashCanOutline: mdiTrashCanOutline,
      star: mdiStar,
      starOutline: mdiStarOutline,
      cog: mdiCog,
      cogOutline: mdiCogOutline,
      facebook: mdiFacebook,
      facebookMessenger: mdiFacebookMessenger,
      whatsapp: mdiWhatsapp,
      messageAlert: mdiMessageAlert,
      messageAlertOutline: mdiMessageAlertOutline,
      image: mdiImage,
      imageAlbum: mdiImageAlbum,
      imageOutline: mdiImageOutline,
      imageMultipleOutline: mdiImageMultipleOutline,
      imageEditOutline: mdiImageEditOutline,
      commentPlusOutline: mdiCommentPlusOutline,
      accountCancelOutline: mdiAccountCancelOutline,
      accountCheckOutline: mdiAccountCheckOutline,
      fileDocumentEditOutline: mdiFileDocumentEditOutline,
      calendarRangeOutline: mdiCalendarRangeOutline,
      accountBoxMultipleOutline: mdiAccountBoxMultipleOutline,
      accountCircleOutline: mdiAccountCircleOutline,
      alarm: mdiAlarm,
      tagMultipleOutline: mdiTagMultipleOutline,
      commentOutline: mdiCommentOutline,
      heart: mdiHeart,
      heartOutline: mdiHeartOutline,
      cloudAlert: mdiCloudAlert,
      commentSearchOutline: mdiCommentSearchOutline,
      accountPlusOutline: mdiAccountPlusOutline,
      emoticonSadOutline: mdiEmoticonSadOutline,
      logout: mdiLogout,
      shieldAccount: mdiShieldAccount,
      accountCancel: mdiAccountCancel,
      accountMultiple: mdiAccountMultiple,
      account: mdiAccount,
      acountEditOutline: mdiAccountEditOutline,
      bookOpenPageVariantOutline: mdiBookOpenPageVariantOutline,
      cloudUploadOutline: mdiCloudUploadOutline,
      dotsHorizontal: mdiDotsHorizontal,
      newspaperVariantOutline: mdiNewspaperVariantOutline,
      emailOutline: mdiEmailOutline,
      humanMaleChild: mdiHumanMaleChild,
      chartBellCurveCumulative: mdiChartBellCurveCumulative,
      sort: mdiSort,
      rotate3d: mdiRotate3dVariant,
      family: mdiHumanMaleFemale,
    },
  },
}; // your options

const vueObj = new Vuetify(opts);

Vue.use(VuetifyToast, {
  x: "right", // default
  y: "bottom", // default
  color: "info", // default
  icon: "",
  iconColor: "", // default
  classes: "subtitle-2",
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: true, // default
  vertical: false, // default
  queueable: false, // default
  showClose: true, // default
  closeText: "", // default
  closeIcon: mdiClose, // default
  closeColor: "", // default
  slot: [], //default
  shorts: {
    custom: {
      color: "purple",
    },
  },
  property: "$toast", //
  $vuetify: vueObj.framework,
});

export default vueObj;
