/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      if (process.env.VUE_APP_IS_PROD == "false") {
        console.log(
          "App is being served from cache by a service worker.\n" +
            "For more details, visit https://goo.gl/AFskqB"
        );
      }
    },
    registered() {
      if (process.env.VUE_APP_IS_PROD == "false") {
        console.log("Service worker has been registered.");
      }
    },
    cached() {
      if (process.env.VUE_APP_IS_PROD == "false") {
        console.log("Content has been cached for offline use.");
      }
    },
    updatefound() {
      if (process.env.VUE_APP_IS_PROD == "false") {
        console.log("New content is downloading.");
      }
    },
    updated() {
      if (process.env.VUE_APP_IS_PROD == "false") {
        console.log("New content is available; Refresh...");
      }
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    },
    offline() {
      if (process.env.VUE_APP_IS_PROD == "false") {
        console.log(
          "No internet connection found. App is running in offline mode."
        );
      }
    },
    error(error) {
      if (process.env.VUE_APP_IS_PROD == "false") {
        console.error("Error during service worker registration:", error);
      }
    },
  });
}
