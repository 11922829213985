const UserHome = () => import(/* webpackChunkName: "user" */ '../views/user/Home.vue')
const UserContact = () => import(/* webpackChunkName: "user" */ '../views/user/Contact.vue')
const UserFollowing = () => import(/* webpackChunkName: "user" */ '../views/user/Following.vue')

const userroutes = [
    {
        path: '',
        name: 'user_home',
        component: UserHome
    },
    {
        path: 'contact',
        name: 'user_contact',
        component: UserContact
    },
    {
        path: 'following',
        name: 'user_following',
        component: UserFollowing
    },
    {
        path: '*',
        redirect: to => {
            return "/user/" + to.params.username;
        }
    }
];

export default userroutes;