import Vue from 'vue';
import Vuex from 'vuex';

import { account } from './account/account.module';
import { diary } from './diary/diary.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        account,
        diary
    },
    strict: process.env.NODE_ENV !== 'production'
});
