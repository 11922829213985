import { blogPostService } from "../../_services";
import { responseHelpers } from "../../_helpers";
import { event } from "vue-analytics";

const getDefaultState = () => {
  return {
    current_page: 0,
    is_loading: false,
    list: [],
    error: null,
    has_more: false,
    unit_pages: 0,
    unit_posts: 0,

    current_category: null,

    is_saving: false,
  };
};

const state = getDefaultState();

const actions = {
  load({ dispatch, commit, rootGetters }, page) {
    commit("loadRequest");

    blogPostService
      .load(rootGetters["diary/getName"](), page)
      .then(function(response) {
        commit("loadSuccess", response.data);
        commit("setCurrentCategory", null);
      })
      .catch(function(error) {
        commit("loadFailure", error);
        dispatch("error/handle", error, { root: true });
      });
  },
  loadInCategory({ dispatch, commit, rootGetters }, { page, category }) {
    commit("loadRequest");

    blogPostService
      .getPostsInCategory(rootGetters["diary/getName"](), category, page)
      .then(function(response) {
        commit("loadSuccess", response.data);
        commit("setCurrentCategory", category);
      })
      .catch(function(error) {
        commit("loadFailure", error);
        dispatch("error/handle", error, { root: true });
      });
  },
  reset({ commit }) {
    commit("reset");
  },
  create({ commit, rootGetters, dispatch }, post) {
    return new Promise((resolve, reject) => {
      //commit('saveRequest');

      blogPostService
        .create(rootGetters["diary/getName"](), post)
        .then(function(response) {
          commit("createSuccess", response.data);
          dispatch("diary/timeline/reset", null, { root: true });

          resolve(response);
          event("Blog-Post", "Created", response.data.id, 1);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  update({ commit, dispatch }, { id, post }) {
    return new Promise((resolve, reject) => {
      commit("saveRequest");

      blogPostService
        .update(id, post)
        .then(function(response) {
          commit("reset");
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          commit("saveFailure", error);
          reject(error);
        });
    });
  },
  abuse({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("abuseRequest", id);

      blogPostService
        .abuse(id)
        .then(function() {
          commit("abuseSuccess", id);
          resolve(id);
          event("Blog-Post", "Abused", id, 1);
        })
        .catch(function(error) {
          commit("abuseFailure");
          reject(error);
        });
    });
  },
  delete({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      commit("deleteRequest", id);

      blogPostService
        .delete(id)
        .then(function() {
          commit("deleteSuccess", id);
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(id);
          event("Blog-Post", "Deleted", id, 1);
        })
        .catch(function(error) {
          commit("deleteFailure");
          //dispatch('error/handle', error, { root: true });
          reject(error);
        });
    });
  },
  updatePrivacy({ commit }, { id, privacy }) {
    return new Promise((resolve, reject) => {
      blogPostService
        .updatePostPrivacy(id, privacy)
        .then(function() {
          commit("successUpdatePrivacy", { id, privacy });
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updatePublishDate({ commit }, { id, date }) {
    return new Promise((resolve, reject) => {
      blogPostService
        .updatePostDate(id, date)
        .then(function() {
          commit("successUpdateDate", { id, date });
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updateChildren({ commit }, { id, children }) {
    var child_ids = [];

    children.forEach((child) => {
      child_ids.push(child.id);
    });

    return new Promise((resolve, reject) => {
      blogPostService
        .updatePostChildren(id, child_ids)
        .then(function() {
          commit("successUpdateChildren", { id, children });
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updateCategories({ commit }, { id, categories }) {
    var cats_ids = [];

    categories.forEach((child) => {
      cats_ids.push(child.id);
    });

    return new Promise((resolve, reject) => {
      blogPostService
        .updatePostCategories(id, cats_ids)
        .then(function() {
          commit("successUpdateCategories", { id, categories: categories });
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updateCover({ commit }, { id, images }) {
    var image_ids = [];

    images.forEach((img) => {
      image_ids.push(img.id);
    });

    return new Promise((resolve, reject) => {
      blogPostService
        .updateCover(id, image_ids)
        .then(function() {
          commit("successUpdateCover", { id, images: images });
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  }  
};

const mutations = {
  loadRequest(state) {
    state.is_loading = true;
  },
  loadSuccess(state, response) {
    state.is_loading = false;
    state.list = response.data;
    state.current_page = response.current_page;
    state.unit_pages = response.unit_pages;
    state.unit_posts = response.unit_posts;
    state.error = null;
    state.has_more = state.unit_pages > state.page;
  },
  loadFailure(state, error) {
    state.is_loading = false;
    state.error = responseHelpers.errorTextFromResponse(error);
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },

  saveRequest(state) {
    state.is_saving = true;
  },
  createSuccess(state, post) {
    state.is_saving = false;
    state.list.unshift(post);
  },
  saveFailure(state) {
    state.is_saving = false;
  },

  updateSuccess(state) {
    state.is_saving = false;
  },

  deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
    state.list = state.list.map((post) =>
      post.id === id ? { ...post, deleting: true } : post
    );
  },
  deleteSuccess(state, id) {
    state.list = state.list.filter((post) => post.id !== id);
  },
  deleteFailure(state, id) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.list = state.list.map((post) => {
      if (post.id === id) {
        // make copy of user without 'deleting:true' property
        const { ...postCopy } = post;
        // return copy of user with 'deleteError:[error]' property
        return { ...postCopy };
      }

      return post;
    });
  },
  successUpdatePrivacy(state, { id, privacy }) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.id == id) {
          obj.privacy = privacy;
          return;
        }
      }
    }
  },
  successUpdateDate(state, { id, date }) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.id == id) {
          obj.show_date = date;
          return;
        }
      }
    }
  },
  successUpdateChildren(state, { id, children }) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.id == id) {
          obj.children = children;
          return;
        }
      }
    }
  },
  successUpdateCategories(state, { id, categories }) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.id == id) {
          obj.categories = categories;
          return;
        }
      }
    }
  },
  successUpdateCover(state, { id, images }) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.id == id) {
          obj.cover = images;
          return;
        }
      }
    }
  },
  setCurrentCategory(state, category) {
    state.current_category = category;
  },
  incraseUnitComments(state, { id, value }) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.id == id) {
          obj.unit_comments += value;
          return;
        }
      }
    }
  },
};

const getters = {
  getNewsById: (state) => (id) => {
    return state.list.find((news) => news.id === id);
  },
  getNewsByUrlName: (state) => (urlName) => {
    return state.list.find((news) => news.url_name === urlName);
  },
};

export const news = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
