import { timelineService } from '../../_services';
import { timelineCreator } from '../../_helpers'
import Vue from 'vue'

const getDefaultState = () => {
    return {
        page: 0,
        is_loading: false,
        init_load: false,
        has_more: false,
        list: [],
        error: null,

        is_creating: false
    }
}

const state = getDefaultState();

const actions = {
    load({ commit, state, rootGetters }) {
        commit('loadRequest');

        const tempPage = state.page += 1;

        timelineService.load(rootGetters['diary/getName'](), tempPage, 0)
            .then(function (response) {
                commit('loadSuccess', { response: response.data, diary: rootGetters['diary/getProfile']() });
            })
            .catch(function (err) {
                commit('loadFailure', err);
            });
    },
    reset({ commit }) {
        commit('reset');
    },
    delete({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('deleteRequest', id);

            timelineService.delete(id)
                .then(function () {
                    commit('deleteSuccess', id);
                    resolve(id);
                })
                .catch(function (error) {
                    commit('deleteFailure', id);
                    reject(error);
                });
        });
    },
    create({ dispatch, commit, rootGetters }, item) {
        return new Promise((resolve, reject) => {
            commit('createRequest');

            timelineService.create(rootGetters['diary/getName'](), item)
                .then(function () {
                    commit('createSuccess', item);
                    dispatch('reset');
                    dispatch('load');

                    resolve(item);
                })
                .catch(function (error) {
                    commit('createFailure');
                    reject(error);
                });
        });
    },
    abuse({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('abuseRequest', id);

            timelineService.abuse(id)
                .then(function () {
                    commit('abuseSuccess', id);
                    resolve(id);
                })
                .catch(function (error) {
                    commit('abuseFailure');
                    reject(error);
                });
        });
    }
};

const mutations = {
    loadRequest(state) {
        state.is_loading = true;

        if (state.page == 0)
            state.init_load = true;
    },
    loadSuccess(state, { response, diary }) {
        state.is_loading = false;

        state.list = state.list.concat(timelineCreator.formatedTimeline(response.data, diary));
        state.page = response.current_page;
        state.has_more = response.has_more;
        state.init_load = false;
    },
    loadFailure(state, error) {
        state.is_loading = false;
        state.init_load = false;
        state.error = error;
    },

    reset(state) {
        Object.assign(state, getDefaultState());
    },

    deleteRequest(state, id) {
        // add 'deleting:true' property to user being deleted
        state.list = state.list.map(post =>
            post.id === id
                ? { ...post, deleting: true }
                : post
        );
    },
    deleteSuccess(state, id) {
        state.list = state.list.filter(post => post.id !== id);
    },
    deleteFailure(state, id) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        state.list = state.list.map(post => {
            if (post.id === id) {
                // make copy of user without 'deleting:true' property
                const { ...postCopy } = post;
                // return copy of user with 'deleteError:[error]' property
                return { ...postCopy };
            }

            return post;
        });
    },

    createRequest(state) {
        state.is_creating = true;
    },
    createSuccess(state, item) {
        state.is_creating = false;
        state.list.push(item);
    },
    createFailure(state) {
        state.is_creating = false;
    },

    abuseRequest(state, id) {
        var item = findItem(state.list, id);

        if (item) {
            Vue.set(item, "is_abusing", true);
        }
    },
    abuseSuccess(state, id) {
        var item = findItem(state.list, id);

        if (item) {
            Vue.set(item, "is_abusing", false);
        }
    },
    abuseFailure(state, id) {
        var item = findItem(state.list, id);

        if (item) {
            Vue.set(item, "is_abusing", false);
        }
    }
};

function findItem(items, id) {
    const unit = items.length;

    for (let i = 0; i < unit; i++) {
        const obj = items[i];

        if (obj.id === id) {
            return obj;
        }
    }
    return null;
}

export const timeline = {
    namespaced: true,
    state,
    actions,
    mutations
};