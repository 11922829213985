const Posts = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/blog/Posts.vue')
const NewBlogPost = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/blog/New.vue')
const EditBlogPost = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/blog/Edit.vue')

const AdminDiarySettingsMaster = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/settings/Master.vue')
const AdminDiarySettings = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/settings/Diary.vue')
const AdminStyleSettings = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/settings/Style.vue')
const PrivacySettings = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/settings/Privacy.vue')
const Blocking = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/settings/Blocking.vue')
const AdminMenuSettings = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/settings/Menu.vue')

const BlogComments = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/comments/Comments.vue')
const BlogCommentsMaster = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/comments/Master.vue')

const Members = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/members/Home.vue')

const ManageMedias = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/album/ManageMedias.vue')

const diaryadminroutes = [
  // --. SETTINGS

  {
    path: "settings",
    component: AdminDiarySettingsMaster,
    children: [
      {
        path: "",
        name: "diary_admin_settings",
        component: AdminDiarySettings,
      },
      {
        path: "menu",
        name: "diary_admin_menu",
        component: AdminMenuSettings,
      },
      {
        path: "style",
        name: "diary_admin_style",
        component: AdminStyleSettings,
      },
      {
        path: "privacy",
        name: "diary_admin_privacysettings",
        component: PrivacySettings,
      },
      {
        path: "blocking",
        name: "diary_admin_blocking",
        component: Blocking,
      }  
    ],
  },

  //--- Photos
  {
    path: "photos",
    name: "diary_admin_photos",
    component: ManageMedias,
  },

  // --- DIARY / Posts
  {
    path: "diary",
    name: "diary_admin_posts",
    component: Posts,
  },
  {
    path: 'diary/new',
    name: 'diary_blog_new',
    component: NewBlogPost
},
{
    path: 'diary/edit/:id',
    name: 'diary_blog_edit',
    component: EditBlogPost
},
  {
    path: "diary/:type",
    name: "diary_admin_posts_type",
    component: Posts,
  },

  // --- Members (Friends/Family)
  {
    path: "members",
    name: "diary_admin_members",
    component: Members,
  },


  // --- Comments Media/blogpost/guestbook
  {
    path: "comments",
    component: BlogCommentsMaster,
    children: [
      {
        path: ":status",
        name: "diary_admin_comments_status",
        component: BlogComments,
      },
      {
        path: "",
        name: "diary_admin_comments",
        component: BlogComments,
      }   
    ],
  },
  {
    path: "*",
    redirect: (to) => {
      return "/" + to.params.diary;
    },
  }
];

export default diaryadminroutes;
