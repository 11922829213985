import ChildRoutes from './child'
import AdminRoutes from './diaryadmin'

const Home = () => import(/* webpackChunkName: "diary-main" */ '../views/diary/pages/Home.vue')
const Blog = () => import(/* webpackChunkName: "diary-main" */ '../views/diary/pages/Blog.vue')
const BlogPost = () => import(/* webpackChunkName: "diary-main" */ '../views/diary/pages/BlogPost.vue')
const Albums = () => import(/* webpackChunkName: "diary-album" */ '../views/diary/pages/Albums.vue')
const AlbumMedia = () => import(/* webpackChunkName: "diary-album" */ '../views/diary/pages/AlbumMedia.vue')
const AlbumProfile = () => import(/* webpackChunkName: "diary-album" */ '../views/diary/pages/AlbumProfile.vue')
const ChildProfile = () => import(/* webpackChunkName: "diary-main" */ '../views/diary/pages/childprofile/Master.vue')
const Guestbook = () => import(/* webpackChunkName: "diary-main" */ '../views/diary/pages/Guestbook.vue')
const GuestbookPost = () => import(/* webpackChunkName: "diary-main" */ '../views/diary/pages/GuestbookPost.vue')
const Followers = () => import(/* webpackChunkName: "diary-main" */ '../views/diary/pages/Followers.vue')
const Children = () => import(/* webpackChunkName: "diary-child" */ '../views/diary/pages/Children.vue')
const Search = () => import(/* webpackChunkName: "diary-main" */ '../views/diary/pages/Search.vue')


const ManageAlbumMedia = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/album/ManageAlbumMedia.vue')
const AdminMaster = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/Master.vue')

const AdminAlbumUploadForm = () => import(/* webpackChunkName: "diary-manage" */ '../views/diary/admin/album/AlbumUploadForm.vue')


const diaryroutes = [
    {
        path: '',
        name: 'diary_home',
        component: Home
    },
    {
        path: 'diary',
        name: 'diary_blog',
        component: Blog
    },
    {
        path: 'followers',
        name: 'diary_followers',
        component: Followers
    },
    {
        path: 'search',
        name: 'diary_search',
        component: Search
    },
    {
        path: 'diary/category/:category',
        name: 'diary_blog_category',
        component: Blog
    },
    {
        path: 'diary/:name/comment/:id',
        name: 'diary_blog_post_comment',
        component: BlogPost
    },
    {
        path: 'diary/:name',
        name: 'diary_blog_post',
        component: BlogPost
    },
    {
        path: 'album',
        name: 'diary_albums',
        component: Albums
    },
    {
        path: 'album/media/:id',
        name: 'diary_album_media',
        component: AlbumMedia
    },
    {
        path: 'album/media/:id/comment/:commentid',
        name: 'diary_album_media_comment',
        component: AlbumMedia
    },
    {
        path: 'album/:name/upload',
        name: 'diary_album_upload',
        component: AdminAlbumUploadForm
    },
    {
        path: 'album/:name/manage',
        name: 'diary_album_manage',
        component: ManageAlbumMedia
    },
    {
        path: 'album/:name',
        name: 'diary_album_profile',
        component: AlbumProfile
    },
    {
        path: 'children',
        name: 'diary_children',
        component: Children
    },
    {
        path: 'child/:name',
        //name: 'diary_child_profile',
        component: ChildProfile,
        children: ChildRoutes
    },
    {
        path: 'guestbook/:id',
        name: 'diary_guestbook_post',
        component: GuestbookPost
    },
    {
        path: 'guestbook',
        name: 'diary_guestbook',
        component: Guestbook
    },
    {
        path: 'admin',
        //name: 'diary_child_profile',
        component: AdminMaster,
        children: AdminRoutes
    },

    {
        path: '*',
        redirect: to => {
            return "/" + to.params.diary;
        }
    }
];

export default diaryroutes;