import { albumsService } from "../../_services";
import { responseHelpers } from "../../_helpers";
import { event } from "vue-analytics";

const getDefaultState = () => {
  return {
    current_page: 0,
    is_loading: false,
    list: [],
    unit_items: 0,
    unit_pages: 0,
  };
};

const state = getDefaultState();

const actions = {
  load({ commit, rootGetters }, page) {
    return new Promise((resolve, reject) => {
      commit("loadRequest");

      albumsService
        .load(rootGetters["diary/getName"](), page)
        .then(function(response) {
          commit("loadSuccess", response.data);
          resolve(response.data);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  reset({ commit }) {
    commit("reset");
  },
  deletedImage({ commit, dispatch }, id) {
    commit("deletedImage", id);
    dispatch("diary/timeline/reset", null, { root: true });
  },
  mediaUploadedInAlbum({ commit, dispatch }, albumId) {
    commit("mediaUploadedInAlbum", albumId);
    dispatch("diary/timeline/reset", null, { root: true });
  },
  create({ commit, rootGetters }, data) {
    return new Promise((resolve, reject) => {
      albumsService
        .create(rootGetters["diary/getName"](), data)
        .then(function(response) {
          commit("reset");
          resolve(response);
          event("Album", "Created", response.data.id, 1);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  createInDiary({ commit, rootGetters }, { diary, data }) {
    return new Promise((resolve, reject) => {
      albumsService
        .create(diary, data)
        .then(function(response) {
          if (rootGetters["diary/getName"]() == diary) {
            commit("reset");
          }
          resolve(response);
          event("Album", "Created", response.data.id, 1);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  update({ commit, dispatch }, album) {
    return new Promise((resolve, reject) => {
      albumsService
        .update(album.id, album)
        .then(function(response) {
          commit("updateSuccess", response.data);
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  delete({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      commit("deleteRequest", id);

      albumsService
        .delete(id)
        .then(function() {
          commit("deleteSuccess", id);
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(id);
          event("Album", "Deleted", id, 1);
        })
        .catch(function(error) {
          commit("deleteFailure");
          reject(error);
        });
    });
  },
  deleteMedia({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      albumsService
        .deleteMedia(id)
        .then(function(response) {
          commit("deletedImage", id);
          dispatch("diary/timeline/reset", null, { root: true });
          dispatch("diary/mediaDeleted", response.data.file_name, {
            root: true,
          });
          dispatch("diary/children/mediaDeleted", response.data.file_name, {
            root: true,
          });
          resolve(id);
          event("Media", "Deleted", id, 1);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  changeAlbumCover({ commit }, { album_id, media }) {
    return new Promise((resolve, reject) => {
      albumsService
        .changeCover(album_id, media.id)
        .then(function() {
          commit("setDefaultImage", { album_id, media });
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updateMediaDate({ dispatch }, { media_id, date }) {
    return new Promise((resolve, reject) => {
      albumsService
        .updateMediaDate(media_id, date)
        .then(function() {
          dispatch("diary/timeline/reset", null, { root: true });
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updateAlbumPrivacy({ commit }, { id, privacy }) {
    return new Promise((resolve, reject) => {
      albumsService
        .updateAlbumPrivacy(id, privacy)
        .then(function() {
          commit("successUpdatePrivacy", { id, privacy });
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
};

const mutations = {
  loadRequest(state) {
    state.is_loading = true;
  },
  loadSuccess(state, response) {
    state.is_loading = false;
    state.list = response.data;
    state.current_page = response.current_page;
    state.unit_pages = response.unit_pages;
    state.unit_items = response.unit_items;
    state.list = state.list.sort(
      (a, b) => new Date(b.updated_time) - new Date(a.updated_time)
    );
  },
  loadFailure(state, error) {
    state.is_loading = false;
    state.error = responseHelpers.errorTextFromResponse(error);
  },
  createSuccess(state, data) {
    state.list = state.list.concat(data);
  },
  updateSuccess(state, data) {
    state.list = [
      ...state.list.filter((element) => element.id !== data.id),
      data,
    ];

    state.list = state.list.sort(
      (a, b) => new Date(b.updated_time) - new Date(a.updated_time)
    );
  },

  reset(state) {
    Object.assign(state, getDefaultState());
  },

  deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
    state.list = state.list.map((post) =>
      post.id === id ? { ...post, deleting: true } : post
    );
  },
  deleteSuccess(state, id) {
    state.list = state.list.filter((post) => post.id !== id);
  },
  deleteFailure(state, id) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.list = state.list.map((post) => {
      if (post.id === id) {
        // make copy of user without 'deleting:true' property
        const { ...postCopy } = post;
        // return copy of user with 'deleteError:[error]' property
        return { ...postCopy };
      }

      return post;
    });
  },
  deletedImage(state, id) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.default_image_id == id) {
          Object.assign(state, getDefaultState());
        }
      }
    }
  },
  mediaUploadedInAlbum(state, id) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.id == id && !obj.image) {
          Object.assign(state, getDefaultState());
          return;
        }
      }
    }
  },
  setDefaultImage(state, { album_id, media }) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.id == album_id) {
          obj.image = media.image;
          obj.default_image_id = media.id;
          return;
        }
      }
    }
  },
  successUpdatePrivacy(state, { id, privacy }) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.id == id) {
          obj.privacy = privacy;
          return;
        }
      }
    }
  },
  successUpdateDate(state, { id, date }) {
    if (state.list && state.list.length > 0) {
      for (var i = 0; i < state.list.length; i++) {
        var obj = state.list[i];

        if (obj.id == id) {
          obj.updated_time = date;
          return;
        }
      }
      state.list = state.list.sort(
        (a, b) => new Date(b.updated_time) - new Date(a.updated_time)
      );
    }
  },
};

const getters = {
  // ...
  getAlbumById: (state) => (id) => {
    return state.list.find((album) => album.id === id);
  },
  getAlbumByUrlName: (state) => (urlName) => {
    return state.list.find((album) => album.url_name === urlName);
  },
};

export const albums = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
