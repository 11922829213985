import * as moment from "moment";

export const diaryTokenStore = {
  getCurrentDiary,
  setToken,
  remove,
  hasValidToken,
};

function hasValidToken(diary) {
  const data = JSON.parse(sessionStorage.getItem("diary_token"));

  if(!data) return false;
  if(data.name != diary) {
    remove();
    return false;
  }

  if (data.token && data.expire) {
    if (moment.utc().isBefore(moment.utc(data.expire))) return true;
    else {
      remove();
      return false;
    }
  }

  remove();
  return false;
}

function getCurrentDiary() {
  const data = JSON.parse(sessionStorage.getItem("diary_token"));

  if (data && data.token && data.expire) {
    if (moment.utc().isBefore(moment.utc(data.expire))) return data;
    else {
      remove();
      return null;
    }
  }

  return null;
}

function remove() {
    sessionStorage.removeItem("diary_token");
}

function setToken(diaryObj, token, expire) {
  var data = {
    name: diaryObj.name,
    title: diaryObj.title,
    token: token,
    expire: expire,
  };

  sessionStorage.setItem("diary_token", JSON.stringify(data));
}
