import { childrenService } from "../../_services";
//import { timelineCreator } from "../../_helpers";

const getDefaultState = () => {
  return {
    is_saving: false,
    is_loading: false,
    error: null,
    show_welcome: false,

    child: {
      id: null,
      name: "",
      url_name: "",
      avatar: "",
      description: "",
    },
    memories: {
      items: [],
      is_loading: false,
      is_saving: false,
    },
  };
};

const state = getDefaultState();

const actions = {
  load({ commit, rootGetters }, name) {
    return new Promise((resolve, reject) => {
      commit("reset");
      commit("loadRequest");

      childrenService
        .getProfile(rootGetters["diary/getName"](), name)
        .then(function(response) {
          commit("loadSuccess", response.data);
          resolve(response.data);
        })
        .catch(function(error) {
          commit("loadFailure", error);
          //dispatch("error/handle", error, { root: true });
          reject(error);
        });
    });
  },
  updateDescription({ commit, dispatch, state }, description) {
    return new Promise((resolve, reject) => {
      childrenService
        .updateChildProfile(state.child.id, { description })
        .then(function(response) {
          commit("updateChild", { description });
          commit(
            "diary/children/updateDescriptionSuccess",
            { id: state.child.id, description },
            { root: true }
          );
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  uploadAvatar({ commit, dispatch, state }, file) {
    return new Promise((resolve, reject) => {
      dispatch(
        "diary/children/uploadAvatar",
        { id: state.child.id, file: file },
        { root: true }
      )
        .then(function(response) {
          commit("updateChild", { avatar: response.data });
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  createMemory({ commit, state, dispatch }, { key, data }) {
    return new Promise((resolve, reject) => {
      childrenService
        .createMemory(state.child.id, key, data)
        .then(function(response) {
          commit("createdMemory", response.data);
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updateMemory({ commit, dispatch }, { id, data }) {
    return new Promise((resolve, reject) => {
      childrenService
        .updateMemory(id, data)
        .then(function(response) {
          commit("updatedMemory", response.data);
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  deleteMemory({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      childrenService
        .deleteMemory(id)
        .then(function(response) {
          commit("deletedMemory", id);
          dispatch("diary/timeline/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  addMemoryCover({ commit }, { id, mediaid }) {
    return new Promise((resolve, reject) => {
      childrenService
        .addMemoryCover(id, mediaid)
        .then(function(response) {
          commit("addedMemoryCover", { id, media: response.data });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  reset({ commit }) {
    commit("reset");
  },
};

const mutations = {
  loadRequest(state) {
    state.is_loading = true;
  },
  loadSuccess(state, data) {
    if (!data.description) data.description = "";

    state.child = { ...data };
    delete state.child.memories;

    state.memories.items = data.memories;
    sortHelper(state);

    state.is_loading = false;
  },
  loadFailure(state, error) {
    state.is_loading = false;
    state.error = error;
  },

  updateChild(state, data) {
    if (data.hasOwnProperty("name")) state.child.name = data.name;

    if (data.hasOwnProperty("description"))
      state.child.description = data.description;

    if (data.hasOwnProperty("avatar")) state.child.avatar = data.avatar;

    if (data.hasOwnProperty("birth")) state.child.birth = data.birth;

    if (data.hasOwnProperty("sex")) state.child.sex = data.sex;
  },

  reset(state) {
    Object.assign(state, getDefaultState());
  },
  welcomeStatus(state, show) {
    state.show_welcome = show;
  },

  createdMemory(state, data) {
    state.memories.items.push(data);
    state.memories.is_saving = false;

    sortHelper(state);
  },

  updatedMemory(state, data) {
    for (var i = 0; i < state.memories.items.length; i++) {
      var obj = state.memories.items[i];

      if (obj.id == data.id) {
        obj.media = data.media;
        obj.date = data.date;
      }
    }
    state.memories.is_saving = false;

    sortHelper(state);
  },
  deletedMemory(state, id) {
    for (var i = 0; i < state.memories.items.length; i++) {
      var obj = state.memories.items[i];

      if (obj.id == id) {
        state.memories.items.splice(i, 1);
      }
    }
  },
  addedMemoryCover(state, { id, media }) {
    for (var i = 0; i < state.memories.items.length; i++) {
      var obj = state.memories.items[i];

      if (obj.id == id) {
        obj.media = media;
      }
    }
  },
};

function sortHelper(state) {
  state.memories.items = state.memories.items
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .reverse();
}

export const childprofile = {
  namespaced: true,
  state,
  actions,
  mutations,
};
