import { categoriesService } from '../../_services';
import { event } from "vue-analytics";

const getDefaultState = () => {
    return {
        is_loading: false,
        list: [],
        is_saving: false
    }
}

const state = getDefaultState();

const actions = {
    load({ commit, rootGetters }) {
        return new Promise((resolve, reject) => {
            commit('loadRequest');

            categoriesService.load(rootGetters['diary/getName']())
                .then(function (response) {
                    commit('loadSuccess', response.data);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit('loadFailure');
                    reject(error);
                });
        });
    },
    create({ commit, rootGetters }, data) {
        return new Promise((resolve, reject) => {
            commit('saveRequest');

            categoriesService.create(rootGetters['diary/getName'](), data)
                .then(function (response) {
                    commit('createSuccess', response.data);
                    resolve(response.data);
                    event("Blog-Category", "Created", response.data.id, 1);
                })
                .catch(function (error) {
                    commit('saveFailure');
                    reject(error);
                });
        });
    },
    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            commit('saveRequest');

            categoriesService.update(data.id, data)
                .then(function (response) {
                    commit('updateSuccess', response.data);
                    resolve(response.data);
                })
                .catch(function (error) {
                    commit('saveFailure');
                    reject(error);
                });
        });
    },
    delete({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('deleteRequest', id);

            categoriesService.delete(id)
                .then(function () {
                    commit('deleteSuccess', id);
                    resolve(id);
                    event("Blog-Category", "Deleted", id, 1);
                })
                .catch(function (error) {
                    commit('deleteFailure');
                    reject(error);
                });
        });
    },
    reset({ commit }) {
        commit('reset');
    }
};

const mutations = {
    loadRequest(state) {
        state.is_loading = true;
    },
    loadSuccess(state, data) {
        state.is_loading = false;
        state.list = data;
    },
    loadFailure(state) {
        state.is_loading = false;
    },

    saveRequest(state) {
        state.is_saving = true;
    },
    createSuccess(state, data) {
        state.is_saving = false;
        state.list.push(data);
    },
    updateSuccess(state, data) {
        state.is_saving = false;

        state.list = [
            ...state.list.filter(element => element.id !== data.id),
            data
        ];
    },
    saveFailure(state) {
        state.is_saving = false;
    },

    reset(state) {
        Object.assign(state, getDefaultState());
    },

    deleteRequest(state, id) {
        // add 'deleting:true' property to user being deleted
        state.list = state.list.map(post =>
            post.id === id
                ? { ...post, deleting: true }
                : post
        );
    },
    deleteSuccess(state, id) {
        state.list = state.list.filter(post => post.id !== id);
    },
    deleteFailure(state, id) {
        // remove 'deleting:true' property and add 'deleteError:[error]' property to user
        state.list = state.list.map(post => {
            if (post.id === id) {
                // make copy of user without 'deleting:true' property
                const { ...postCopy } = post;
                // return copy of user with 'deleteError:[error]' property
                return { ...postCopy };
            }

            return post;
        });
    }
};

const getters = {
    // ...
    getCategoryById: (state) => (id) => {
        return state.list.find(cat => cat.id === id);
    }
};

export const categories = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};