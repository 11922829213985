import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'

import { accountStore } from '../store/browser';

const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue')

const Offers = () => import(/* webpackChunkName: "offers", webpackPrefetch: true */ '../views/main/offers/Offers.vue')

const News = () => import(/* webpackChunkName: "news", webpackPrefetch: true */ '../views/main/news/Cards.vue')
const NewsItem = () => import(/* webpackChunkName: "news", webpackPrefetch: true */ '../views/main/news/Item.vue')
const NewsForm = () => import(/* webpackChunkName: "adm" */ '../views/main/admin/NewsForm.vue')
const NewsList = () => import(/* webpackChunkName: "adm" */ '../views/main/admin/NewsList.vue')

const Contact = () => import(/* webpackChunkName: "main", webpackPrefetch: true */ '../views/main/Contact.vue')
const Error404 = () => import(/* webpackChunkName: "errors", webpackPrefetch: true */ '../views/errors/404.vue')
const Terms = () => import(/* webpackChunkName: "main", webpackPrefetch: true */ '../views/main/Terms.vue')
const Faq = () => import(/* webpackChunkName: "main", webpackPrefetch: true */ '../views/main/Faq.vue')
const Privacy = () => import(/* webpackChunkName: "main", webpackPrefetch: true */ '../views/main/Privacy.vue')
const CreateAccount = () => import(/* webpackChunkName: "auth", webpackPrefetch: true */ '../views/main/CreateAccount.vue')
const Login = () => import(/* webpackChunkName: "auth", webpackPrefetch: true */ '../views/main/Login.vue')
const ForgotPassword = () => import(/* webpackChunkName: "auth", webpackPrefetch: true */ '../views/main/ForgotPassword.vue')
const Confirm = () => import(/* webpackChunkName: "main", webpackPrefetch: true */ '../views/main/Confirm.vue')
const ResetPassword = () => import(/* webpackChunkName: "auth", webpackPrefetch: true */ '../views/main/ResetPassword.vue')
const Diaries = () => import(/* webpackChunkName: "main", webpackPrefetch: true */ '../views/main/Diaries.vue')
const Search = () => import('../views/main/Search.vue')
const CreateDiary = () => import('../views/main/CreateDiary.vue')

const Page = () => import('../views/main/Page.vue')
const PageForm = () => import(/* webpackChunkName: "adm" */ '../views/main/admin/SystemPageForm.vue')
const ManagePages = () => import(/* webpackChunkName: "adm" */ '../views/main/admin/SystemPageList.vue')

const UserMaster = () => import(/* webpackChunkName: "user" */ '../views/user/Master.vue')
import UserRoutes from './user'

const DiaryMaster = () => import(/* webpackChunkName: "diary-main" */ '../views/diary/Master.vue')
import DiaryRoutes from './diary'

const AccountMaster = () => import(/* webpackChunkName: "account" */ '../views/account/Master.vue')
import AccountRoutes from './account'

const Invite = () => import(/* webpackChunkName: "invite" */ '../views/main/Invite.vue')

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/offers',
        name: 'offers',
        component: Offers
    },
    {
        path: '/offers/category/:id',
        name: 'offers_category',
        component: Offers
    },
    {
        path: '/news/edit/:id',
        name: 'news_edit',
        component: NewsForm
    },
    {
        path: '/news/new',
        name: 'news_new',
        component: NewsForm
    },
    {
        path: '/news/manage',
        name: 'news_manage',
        component: NewsList
    },
    {
        path: '/news',
        name: 'news',
        component: News
    },    
    {
        path: '/news/:name',
        name: 'news_item',
        component: NewsItem
    },
    {
        path: '/news/category/:category',
        name: 'news_category',
        component: News
    },
    {
        path: '/terms',
        name: 'terms',
        component: Terms
    },
    {
        path: '/diaries',
        name: 'diaries',
        component: Diaries
    },
    {
        path: '/confirm/:key',
        name: 'confirm',
        component: Confirm
    },
    { path: '/confirm', redirect: '/' },
    {
        path: '/privacy',
        name: 'privacy',
        component: Privacy
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/error/404',
        name: 'error_404',
        component: Error404
    },
    {
        path: '/signup',
        name: 'createaccount',
        component: CreateAccount
    },
    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: ForgotPassword
    },
    {
        path: '/resetpassword',
        name: 'resetpassword',
        component: ResetPassword
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/diary/create',
        name: 'diary_create',
        component: CreateDiary
    },
    {
        path: '/search',
        name: 'search',
        component: Search
    },
    {
        path: '/user/:username',
        component: UserMaster,
        //name: 'user_profile',
        children: UserRoutes
    },
    {
        path: '/account',
        component: AccountMaster,
        //name: 'user_profile',
        children: AccountRoutes
    },
    {
        path: '/page/new',
        name: 'page_new',
        component: PageForm
    },
    {
        path: '/page/manage',
        name: 'page_manage',
        component: ManagePages
    },
    {
        path: '/page/:name',
        name: 'page',
        component: Page
    },
    {
        path: '/page/edit/:id',
        name: 'page_edit',
        component: PageForm
    },
    {
        path: '/invite',
        name: 'invite',
        component: Invite
    },
    {
        path: '/:diary',
        component: DiaryMaster,
        //name: 'diary',
        children: DiaryRoutes
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        else {
            let scrollTo = 0

            if (to.hash) {
                scrollTo = to.hash

                if(scrollTo == "start") {
                    return null;
                }
                else {
                    return goTo(scrollTo)
                }
            }
            else if (to.meta && to.meta.scroll == false)
                return;
            else if (to.meta && to.meta.scrollTo)
                return goTo(to.meta.scrollTo)
            else
                return { x: 0, y: 0 }
        }
    }
});

export default router

router.beforeEach((to, _from, next) => {
    const notAuthorizedPages = ['/login', '/register', '/'];
    const authorizedPages = ['/account', '/diary/create'];

    const userSession = accountStore.currentUser();

    if (notAuthorizedPages.includes(to.path) && userSession) {
        var diary = userSession.user.current_diary;

        if (diary && diary.name && diary.id) {
            return next('/' + diary.name);
        }
        else {
            return next('/account');
        }
    }
    else if (!userSession && authorizedPages.includes(to.path)) {
        return next('/');
    }

    next();
})