import { userMessageService } from '../../_services';

const getDefaultState = () => {
    return {
        is_saving: false,

        inbox: {
            is_loading: false,
            list: [],
            current_page: 0,
            error: null,

            has_new_data: false
        }
    }
}

const state = getDefaultState();

const actions = {
    inbox({ commit, state }) {
        return new Promise((resolve, reject) => {
            commit('loadRequest');

            userMessageService.inbox(state.inbox.current_page + 1)
                .then(function (response) {
                    commit('loadSuccess', response.data);
                    commit("account/resetNewMessages", null, { root: true });
                    resolve();
                })
                .catch(function (error) {
                    commit('loadFailure', error);
                    reject(error);
                });
        });
    },
    create({ commit }, { reciver, message }) {
        return new Promise((resolve, reject) => {
            commit('saveRequest');

            userMessageService.create(reciver, message)
                .then(function () {
                    commit('saveSuccess');
                    resolve();
                })
                .catch(function (error) {
                    commit('saveFailure', error);
                    reject(error);
                });
        });
    },
    delete({ commit }, id) {
        return new Promise((resolve, reject) => {
            commit('deleteRequest', id);

            userMessageService.delete(id)
                .then(function () {
                    commit('deleteSuccess', id);
                    resolve();
                })
                .catch(function (error) {
                    commit('deleteFailure', id);
                    reject(error);
                });
        });
    },
    reset({ commit }) {
        commit('reset');
    },
    setIsReadMessage({ commit }, id) {
        commit('setIsReadMessage', id);
    },
    hasNewData({ commit }) {
        commit('hasNewData');
    }
};

const mutations = {
    loadRequest(state) {
        state.inbox.is_loading = true;
    },
    loadSuccess(state, response) {
        state.inbox.is_loading = false;
        state.inbox.list = state.inbox.list.concat(response.data);
        state.inbox.error = null;
        state.inbox.current_page = response.current_page;
    },
    loadFailure(state, error) {
        state.inbox.is_loading = false;
        state.inbox.error = error;
    },

    saveRequest(state) {
        state.is_saving = true;
    },
    saveSuccess(state) {
        state.is_saving = false;
    },
    saveFailure(state) {
        state.is_saving = false;
    },

    //deleteRequest(state, id) {
    //},
    deleteSuccess(state, id) {
        if (state.inbox.list) {
            state.inbox.list = state.inbox.list.filter(post => post.id !== id);
        }
    },
    //deleteFailure(state, id) {
    //},

    setIsReadMessage(state, id) {
        if (state.inbox.list) {
            const item = state.inbox.list.find(mess => mess.id === id);
            if (item)
                item.is_read = true;
        }
    },

    reset(state) {
        Object.assign(state, getDefaultState());
    },
    hasNewData(state) {
        state.inbox.has_new_data = true;
    }
};

const getters = {
    // ...
    getMessageById: (state) => (id) => {
        return state.inbox.list.find(mess => mess.id === id);
    }
};

export const messages = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};