import * as moment from 'moment'

export const accountStore = {
    currentUser,
    remove,
    setUser,
    updateUserInfo
};

function currentUser() {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token && user.expire) {
        if (moment.utc().isBefore(moment.utc(user.expire)))
            return user;
        else {
            remove();
            return null;
        }
    }

    return null;
}

function remove() {
    localStorage.removeItem('user');
}

function setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
}

function updateUserInfo(user) {
    const data = currentUser();
    data.user = user;
    setUser(data);
}