import { diaryService } from "../../_services";
import { children } from "./children.module";
import { albums } from "./albums.module";
import { news } from "./news.module";
import { categories } from "./categories.module";
import { timeline } from "./timeline.module";
import { search } from "./search.module";
import { childprofile } from "./childprofile.module";
import { diaryTokenStore } from "../browser";

const getDefaultState = () => {
  return {
    is_admin: false,
    is_following: false,

    profile: {},
    styles: {},
    settings: {},
    visitor_info: {},
    temp: {
      background: {},
    },

    visit_status: null,
    token_info: null,
  };
};

const state = getDefaultState();

const actions = {
  authorize({ dispatch }, name) {
    return new Promise((resolve, reject) => {
      dispatch("reset");

      diaryService
        .authorize(name)
        .then(function(response) {
          dispatch("initDone", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  load({ dispatch }, name) {
    return new Promise((resolve, reject) => {
      dispatch("reset");

      diaryService
        .load(name)
        .then(function(response) {
          dispatch("initDone", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  login({ getters, dispatch }, password) {
    return new Promise((resolve, reject) => {
      diaryService
        .login(getters["getName"](), password)
        .then(function(response) {
          dispatch("initDone", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  initDone({ commit }, data) {
    commit("loadSuccess", data);

    if (data.diary && data.diary.children)
      commit("children/loadSuccess", data.diary.children);

    if (data.diary && data.diary.categories)
      commit("categories/loadSuccess", data.diary.categories);
  },
  updateDescription({ commit, getters }, about) {
    return new Promise((resolve, reject) => {
      if (!about) about = "";

      diaryService
        .updateAbout(getters.getName(), { about: about })
        .then(function(response) {
          commit("updateDescriptionSuccess", about);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  uploadAvatar({ commit, getters, dispatch }, { file, image_id }) {
    return new Promise((resolve, reject) => {
      diaryService
        .uploadAvatar(getters.getName(), file, image_id)
        .then(function(response) {
          commit("avatarSuccess", response.data);
          dispatch("diary/albums/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  deleteAvatar({ commit, getters }) {
    return new Promise((resolve, reject) => {
      diaryService
        .deleteAvatar(getters.getName())
        .then(function(response) {
          commit("avatarSuccess", null);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  uploadBanner({ commit, getters, dispatch }, { file, image_id }) {
    return new Promise((resolve, reject) => {
      diaryService
        .uploadBanner(getters.getName(), file, image_id)
        .then(function(response) {
          commit("bannerSuccess", response.data);
          dispatch("diary/albums/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  deleteBanner({ commit, getters }) {
    return new Promise((resolve, reject) => {
      diaryService
        .deleteBanner(getters.getName())
        .then(function(response) {
          commit("bannerSuccess", null);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updateSettings({ commit, rootGetters }, { name, data }) {
    return new Promise((resolve, reject) => {
      diaryService
        .updateSettings(rootGetters["diary/getName"](), name, data)
        .then(function() {
          commit("successUpdateSetting", {
            name: name,
            data: data,
          });
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updateDiaryInfo({ commit, getters }, data) {
    return new Promise((resolve, reject) => {
      diaryService
        .updateGeneralSettings(getters.getName(), data)
        .then(function(response) {
          commit("updateDiaryInfoSuccess", data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updatePrivacy({ commit, getters }, data) {
    return new Promise((resolve, reject) => {
      diaryService
        .updatePrivacy(getters.getName(), data)
        .then(function(response) {
          commit("updateDiaryInfoSuccess", data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  updateBackgroundStyle(
    { commit, getters, dispatch },
    { data, file, image_id }
  ) {
    return new Promise((resolve, reject) => {
      diaryService
        .updateBackgroundStyle(getters.getName(), { data, file, image_id })
        .then(function(response) {
          commit("resetTemp", response.data);
          commit("updateBackgroundStyleSuccess", response.data);
          dispatch("diary/albums/reset", null, { root: true });
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  saveStyles({ commit, getters }, data) {
    return new Promise((resolve, reject) => {
      diaryService
        .saveStyles(getters.getName(), data)
        .then(function(response) {
          commit("resetTemp", response.data);
          commit("updateBackgroundStyleSuccess", response.data);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  follow({ commit, getters }) {
    return new Promise((resolve, reject) => {
      diaryService
        .follow(getters.getName())
        .then(function(response) {
          commit("followingSuccess", true);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  unfollow({ commit, getters }) {
    return new Promise((resolve, reject) => {
      diaryService
        .unfollow(getters.getName())
        .then(function(response) {
          commit("followingSuccess", false);
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  mediaDeleted({ commit }, fileName) {
    commit("mediaDeleted", fileName);
  },
  updateWelcomeInfo({ commit }, data) {
    commit("updateWelcomeInfo", data);
  },
  reset({ commit }) {
    commit("reset");
    commit("albums/reset");
    commit("categories/reset");
    commit("children/reset");
    commit("news/reset");
    commit("timeline/reset");
  },
};

const mutations = {
  loadSuccess(state, data) {
    if (data.token_info) {
      diaryTokenStore.setToken(
        data.diary.diary,
        data.token_info.token,
        data.token_info.expire
      );
      state.token_info = data.token_info;
    }

    if (data.diary) {
      state.styles = data.diary.styles;
      state.settings = data.diary.settings;
      state.profile = data.diary.diary;
    }

    state.visitor_info = data.diary.visitor_info;

    if (data.diary.visitor_info) {
      state.is_admin = data.diary.visitor_info.is_admin;
      state.is_following = data.diary.visitor_info.is_following;
      state.roles = data.diary.visitor_info.roles;
    }

    state.visit_status = data.visit_status;
  },
  updateDescriptionSuccess(state, about) {
    state.profile.about = about;
  },
  bannerSuccess(state, data) {
    state.profile.banner = data;
  },
  avatarSuccess(state, data) {
    state.profile.avatar = data;
  },
  updateDiaryInfoSuccess(state, data) {
    state.profile = Object.assign(state.profile, data);
    delete state.profile.password;
  },
  updateBackgroundStyleSuccess(state, data) {
    if (!state.styles.background) {
      state.styles.background = {};
    }

    if (data.hasOwnProperty("background_color"))
      state.styles.background.background_color = data.background_color;

    if (data.hasOwnProperty("background_image_repeat"))
      state.styles.background.background_image_repeat =
        data.background_image_repeat;

    if (data.hasOwnProperty("background_image"))
      state.styles.background.background_image = data.background_image;
  },
  followingSuccess(state, isFollowing) {
    state.is_following = isFollowing;
  },
  reset(state) {
    Object.assign(state, getDefaultState());
  },
  setTempData(state, data) {
    state.temp = data;
  },
  resetTemp(state) {
    state.temp = {};
  },
  userLogout(state) {
    state.is_admin = false;
    state.is_following = false;
    state.visitor_info = {};
  },
  successUpdateSetting(state, { name, data }) {
    state.settings[name] = Object.assign(state.settings[name], data);
  },
  mediaDeleted(state, fileName) {
    if (state.styles.background && state.styles.background.background_image) {
      if (state.styles.background.background_image.indexOf(fileName) > -1) {
        state.styles.background.background_image = "";
      }
    }

    if (state.profile.banner) {
      if (state.profile.banner.filename == fileName) {
        state.profile.banner = null;
      }
    }

    if (state.profile.avatar) {
      if (state.profile.avatar.filename == fileName) {
        state.profile.avatar = null;
      }
    }
  },
  updateWelcomeInfo(state, data) {
    if (state.profile) {
      if (data.title) state.profile.title = data.title;
      if (data.name) state.profile.name = data.name;
      if (data.privacy) state.profile.privacy = data.privacy;
    }
  },
};

const modules = {
  children,
  albums,
  news,
  categories,
  timeline,
  search,
  childprofile,
};

const getters = {
  getName: (state) => () => {
    return state.profile.name;
  },
  getProfile: (state) => () => {
    return state.profile;
  },
};

export const diary = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  modules,
};
