// https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
// https://nicedoc.io/pixari/vue-i18n-extract

import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  if (process.env.VUE_APP_LANG == "sv") {
    return {
      sv: require("@/locales/sv.json"),
    };
  } else {
    return {
      en: require("@/locales/en.json"),
    };
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_LANG || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages(),
  silentFallbackWarn: true,
});
