const Home = () => import(/* webpackChunkName: "diary-child" */ '../views/diary/pages/childprofile/Home.vue')
const Photos = () => import(/* webpackChunkName: "diary-child" */ '../views/diary/pages/childprofile/Photos.vue')
const Diary = () => import(/* webpackChunkName: "diary-child" */ '../views/diary/pages/childprofile/Diary.vue')
const Progress = () => import(/* webpackChunkName: "diary-child" */ '../views/diary/pages/childprofile/Progress.vue')
const Growth = () => import(/* webpackChunkName: "diary-child" */ '../views/diary/pages/childprofile/Growth.vue')
const Memories = () => import(/* webpackChunkName: "diary-child" */ '../views/diary/pages/childprofile/memories/Home.vue')
const ReadMemory = () => import(/* webpackChunkName: "diary-child" */ '../views/diary/pages/childprofile/memories/Read.vue')

const DeleteChild = () => import(/* webpackChunkName: "child-manage" */ '../views/diary/admin/child/DeleteChild.vue')
const ChildGrowthAdmin = () => import(/* webpackChunkName: "child-manage" */ '../views/diary/admin/child/ChildGrowthAdmin.vue')
const ChildProgressAdmin = () => import(/* webpackChunkName: "child-manage" */ '../views/diary/admin/child/ChildProgressAdmin.vue')
const MemoriesHomeAdmin = () => import(/* webpackChunkName: "child-manage" */ '../views/diary/admin/child/memories/Home.vue')
/*const MemoriesFormAdmin = () => import('../views/diary/admin/child/memories/Form.vue')*/


const childroutes = [
  {
    path: "",
    name: "child_home",
    component: Home,
  },
  {
    path: "photos",
    name: "child_photos",
    component: Photos,
  },
  {
    path: "diary",
    name: "child_diary",
    component: Diary,
  },
  {
    path: "progress",
    name: "child_progress",
    component: Progress,
  },
  {
    path: "growth",
    name: "child_growth",
    component: Growth,
  },
  {
    path: "growth/admin",
    name: "child_growth_admin",
    component: ChildGrowthAdmin,
  },
  {
    path: "progress/admin",
    name: "child_progress_admin",
    component: ChildProgressAdmin,
  },
  {
    path: "delete",
    name: "diary_child_delete",
    component: DeleteChild,
  },

  /*{
    path: "memories/admin/:type/:action",
    name: "diary_child_memories_manage",
    component: MemoriesFormAdmin,
  },*/
  {
    path: "memories/admin",
    name: "diary_child_memories_admin",
    component: MemoriesHomeAdmin,
  },
  {
    path: "memories/:type",
    name: "diary_child_memories_read",
    component: ReadMemory,
  },  
  {
    path: "memories",
    name: "diary_child_memories",
    component: Memories,
  },  
];

export default childroutes;
