import { userNotifications } from "../../_services";

const getDefaultState = () => {
  return {
    is_loading: false,
    list: [],
    current_page: 0,
    error: null,
    has_more: false,

    has_new_data: false,
    is_all_updating: false,
  };
};

const state = getDefaultState();

const actions = {
  inbox({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("loadRequest");

      userNotifications
        .inbox(state.current_page + 1)
        .then(function(response) {
          commit("loadSuccess", response.data);
          commit("account/resetNotifications", null, { root: true });
          resolve();
        })
        .catch(function(error) {
          commit("loadFailure", error);
          reject(error);
        });
    });
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("deleteRequest", id);

      userNotifications
        .delete(id)
        .then(function() {
          commit("deleteSuccess", id);
          resolve();
        })
        .catch(function(error) {
          commit("deleteFailure", id);
          reject(error);
        });
    });
  },
  setAllReaded({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setAllReadedRequest");

      userNotifications
        .setAllAsReaded()
        .then(function() {
          commit("setAllReadedSuccess");
          resolve();
        })
        .catch(function(error) {
          commit("setAllReadedFailure");
          reject(error);
        });
    });
  },
  setReaded({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("setReadedRequest", id);

      var data = [];
      data.push(id);

      userNotifications
        .setAsReaded(data)
        .then(function() {
          commit("setReadedSuccess", id);
          resolve();
        })
        .catch(function(error) {
          commit("setReadedFailure", id);
          reject(error);
        });
    });
  },
  setUnReaded({ commit }, id) {
    return new Promise((resolve, reject) => {

      commit("setReadedRequest", id);

      var data = [];
      data.push(id);

      userNotifications
        .setAsUnReaded(data)
        .then(function() {
          commit("setUnReadedSuccess", id);
          resolve();
        })
        .catch(function(error) {
          commit("setReadedFailure", id);
          reject(error);
        });
    });
  },

  reset({ commit }) {
    commit("reset");
  },
  hasNewData({ commit }) {
    commit("hasNewData");
  },
};

const mutations = {
  loadRequest(state) {
    state.is_loading = true;
  },
  loadSuccess(state, response) {
    state.is_loading = false;
    state.list = state.list.concat(response.data);
    state.error = null;
    state.current_page = response.current_page;
    state.has_more = response.has_more;
  },
  loadFailure(state, error) {
    state.is_loading = false;
    state.error = error;
  },

  deleteRequest(state, id) {
    // add 'deleting:true' property to user being deleted
    state.list = state.list.map((post) =>
      post.id === id ? { ...post, deleting: true } : post
    );
  },
  deleteSuccess(state, id) {
    state.list = state.list.filter((post) => post.id !== id);
  },
  deleteFailure(state, id) {
    // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    state.list = state.list.map((post) => {
      if (post.id === id) {
        // make copy of user without 'deleting:true' property
        const { ...postCopy } = post;
        // return copy of user with 'deleteError:[error]' property
        return { ...postCopy };
      }

      return post;
    });
  },

  setAllReadedRequest(state) {
    state.is_all_updating = true;
  },
  setAllReadedSuccess(state) {
    state.is_all_updating = false;

    for (var i = 0; i < state.list.length; i++) {
      state.list[i].is_read = true;
    }
  },
  setAllReadedFailure(state) {
    state.is_all_updating = false;
  },

  setReadedRequest(state, id) {
    state.list = state.list.map((post) =>
      post.id === id ? { ...post, is_updating: true } : post
    );
  },
  setReadedSuccess(state, id) {
    for (var i = 0; i < state.list.length; i++) {
      var item = state.list[i];
      if (item.id == id) {
        item.is_read = true;
        item.is_updating = false;
      }
    }
  },
  setUnReadedSuccess(state, id) {
    for (var i = 0; i < state.list.length; i++) {
      var item = state.list[i];
      if (item.id == id) {
        item.is_read = false;
        item.is_updating = false;
      }
    }
  },
  setReadedFailure(state, id) {
    state.list = state.list.map((post) => {
      if (post.id === id) {
        // make copy of user without 'deleting:true' property
        const { ...postCopy } = post;
        // return copy of user with 'deleteError:[error]' property
        return { ...postCopy };
      }

      return post;
    });
  },

  reset(state) {
    Object.assign(state, getDefaultState());
  },
  hasNewData(state) {
    state.has_new_data = true;
  },
};

const getters = {
  // ...
  getMessageById: (state) => (id) => {
    return state.list.find((mess) => mess.id === id);
  },
};

export const notifications = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
